import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Link,
  Link as RouterLink,
} from "react-router-dom";
import Navbar from "../components/Navbar";
import LocationsNavbar from "../components/LocationsNavbar";
import TrackVisibility from "react-on-screen";
import useOnScreen from "../components/ScreenHook";
import { Waypoint } from "react-waypoint";
import useLocationStore from "../stateManagement/LocationState";
import { useMediaQuery } from "react-responsive";
import { get } from "react-scroll/modules/mixins/scroller";
import { set } from "react-hook-form";

function Locations() {
  // const [locationOrder, setLocationOrder] = useState(["New York City, California, Miami, Harbour Island, Europe"])
  const locationOrder = localStorage.getItem("locations").split(",");
  const [loading, setLoading] = useState(true);
  const store = useLocationStore();
  const API_URL = process.env.REACT_APP_API_URL;
  const screenWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const [locations, setLocations] = useState([]);
  const [venues, setVenues] = useState([]);

  // const getLocationsOrder = async () => {
  //   axios.get(`${API_URL}/api/locations-orders?populate=*`).then(
  //     (response) => {
  //       let order = response.data.data.sort((a, b) => a.attributes.position - b.attributes.position)
  //       let locationOrder = order.map(i => i.attributes.location)
  //       setLocationOrder(locationOrder)
  //     }
  //   )
  // }
  const getVenues = async () => {
    axios.get(`${API_URL}/api/venues?populate=*`).then((response) => {
      // console.log('Venue Array: ',response.data.data.map(i =>{return {...i.attributes, id: i.id}}))
      // setLocations(response.data.data.map(i =>i.attributes.map(e => i.location)))
      // setVenues(response.data.data.map(i => {return i}))

      setVenues(
        response.data.data.map((i) => {
          return { ...i.attributes, id: i.id };
        })
      );
      setLoading(false);
      // setLocations(response.data.data.map(i => i.attributes))
    });
  };

  const justLocations = locations.map((i) => i.location);
  const filteredLocations = [...new Set(justLocations)];

  const refs = filteredLocations.reduce((i, index) => {
    i[index] = React.createRef();
    return filteredLocations;
  }, {});

  const handleClick = (id) =>
    refs[id].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const [scrollPosition, setScrollPosition] = useState(null);
  const [index, setIndex] = useState(null);

  // state

  const setLocationState = (ID) => {
    store.setLocationID(ID);
  };

  // useEffect(() =>{
  //   // window.scrollTo(0, 0)
  // },[])

  const handleClickScroll = (index) => {
    const element = document.getElementById(index);

    var headerOffset = 45;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  const [visable, setVisable] = useState("null");

  const refContainer = useRef(null);

  useEffect(() => {
    // getLocationsOrder()
    getVenues();
    window.scrollTo(0, 0);
  }, []);
  // const justLocations = venues.map(i => i.attributes.map(e => {return e.locations}))
  // if(store.locationIndex != null) {
  // handleClickScroll(2)
  // } else {
  // window.scrollTo(0, 0)

  // }
  return (
    <div className="bg-black  min-h-screen text-white">
      <Navbar />
      <TrackVisibility>
        {/* <div className='w-screen bg-slate-400 h-32'></div> */}
        {loading ? null : (
          <>
            {screenWidth ? (
              <div className=" flex flex-row sticky mt-20 z-40 top-0 justify-center gap-5 bg-black bg-opacity-50 h-10">
                {locationOrder.map((i, index) => {
                  return (
                    <p
                      onClick={() => {
                        handleClickScroll(index);
                      }}
                      className={` cursor-pointer my-auto font-calibreSB ${
                        visable == index ? "text-white" : "text-zinc-400"
                      }`}
                    >
                      {i}
                    </p>
                  );
                })}
              </div>
            ) : (
              <div className="mt-28"></div>
            )}

            {locationOrder.map((i, index) => {
              let currentLocation = i;
              return (
                <div
                  // id={`${index}`}
                  id={index}
                  // ref={refs[index]}
                  key={index}
                >
                  <Waypoint
                    bottomOffset={400}
                    onEnter={() => setVisable(index)}
                  >
                    <div className="font-calibreBold ml-[4.5vw]  text-2xl mt-14 text-white mb-5">
                      {i}
                    </div>
                  </Waypoint>

                  <div class="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-5  w-[90vw] mx-auto">
                    {venues
                      .filter((e) => {
                        return e.location == currentLocation;
                      })
                      .sort((a, b) => a.position - b.position)
                      .map((f, index) => {
                        return (
                          <Link
                            key={index}
                            to={`/location/${f.id}`}
                            component={RouterLink}
                          >
                            <div
                              // onClick={() => {
                              //   localStorage.setItem('location', f.id)
                              //   // console.log(f.id)
                              // }}
                              className={`cursor-pointer pb-4 max-w-sm md:max-w-lg mx-auto rounded-xl shadow-lg  `}
                            >
                              {f.thumbnail.data != null && (
                                <img
                                  className="aspect-auto h-[30vw] w-[40vw] md:h-[20vw] md:w-[30vw] object-cover mb-3"
                                  src={`${f.thumbnail.data.attributes.url}`}
                                />
                              )}
                              <div className="text-2xl font-calibreSB -mb-1">
                                {f.name}
                              </div>
                              <div className="text-sm font-calibreReg">
                                {f.city}
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </TrackVisibility>
    </div>
  );
}

export default Locations;
