import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import Home from './pages/Home';
import Locations from './pages/Locations';
import OurStory from './pages/OurStory';
import LocationPage from './pages/LocationPage';
import Footer from './components/Footer';
import Inquire from './pages/Inquire';
import { useMediaQuery } from 'react-responsive'
import Services from './pages/Services';
import About from './pages/About';


function App() {
  const API_URL = process.env.REACT_APP_API_URL
  const [locations, setLocations] = useState([])
  const [venues, setVenues] = useState([])
  const [loading, setLoading] = useState(true)
  const getVenues = async () => {
    setLoading(true)
    axios.get(`${API_URL}/api/locations-orders?populate=*`)
       .then((response) => {
         console.log( response.data.data.sort((a, b) => a.attributes.position - b.attributes.position))
         let order = response.data.data.sort((a, b) => a.attributes.position - b.attributes.position)
         let locationOrder = order.map(i => i.attributes.location)
         localStorage.setItem('locations', locationOrder)
         setLoading(false)
       })
       .catch((error) => {
         if (error.response) {
           // The request was made and the server responded with a status code
           // that falls out of the range of 2xx
           console.log(error.response.data);
           console.log(error.response.status);
           console.log(error.response.headers);
         } else if (error.request) {
           // The request was made but no response was received
           // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
           // http.ClientRequest in node.js
           console.log(error.request);
         } else {
           // Something happened in setting up the request that triggered an Error
           console.log('Error', error.message);
         }
         console.log(error.config);
       });
   }
   

    // const justLocations = locations.map(i => i.location)
    // const filteredLocations = [...new Set(justLocations)];
    // localStorage.setItem('locations', filteredLocations)

    // const [screenWidth, setScreenWidth] = useState(
    //   window.matchMedia("(min-width: 768px)").matches
    // )
    const screenWidth = useMediaQuery({ query: '(min-width: 768px)' })
  //  useEffect(() => {
  //     window
  //     .matchMedia("(min-width: 768px)")
  //     .addEventListener('change', e => setScreenWidth( e.screenWidth ));
  //   }, [screenWidth]);

    useEffect(() => {
      getVenues()
    },[])
  

  return (
    <div className='bg-black overscroll-contain '>
      <Router>
        <Routes>

          <Route 
          exact path='/' 
          element={
            <Home />
          } />

          <Route 
          exact path='/venues' 
          element={
            <Locations />
          } />

        <Route 
          exact path='/story' 
          element={
            <OurStory />
          } />

        <Route 
          path='/location/:id' 
          element={
            <LocationPage />
          } />

        <Route 
          exact path='/inquire' 
          element={
            <Inquire />
          } />  
           <Route 
          exact path='/services' 
          element={
            <Services />
          } /> 
           <Route 
          exact path='/about' 
          element={
            <About />
          } /> 
        </Routes>
        {screenWidth && !loading?
        
         <Footer />   
          
        :
        <></> 
        }
      </Router>   
    </div>
  );
}

export default App;
