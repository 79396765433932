import React, {useEffect, useState} from 'react'
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'
import everRentLogo from '../assets/everrent_logo.svg'
import { useMediaQuery } from 'react-responsive'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import MobileMenu from './MobileMenu';
// Modal.setAppElement('#yourAppElement');

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
    overflow: 'hidden',
  },
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    overflow: 'hidden',
    padding: '0',
    outline: 'none',
    borderRadius: '0px',
    overflow: 'hidden',

  },
};

function Navbar() {
  
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [isOpen, setModalIsOpen] = useState(false);

  const openModal1 = () => setModalIsOpen(true);
  const closeModal1 = () => setModalIsOpen(false);

  const [modalVisible, setModalVisible] = useState(false);
  const modalToggle = () => {
       setModalVisible(!modalVisible)
    }
  const screenWidth = useMediaQuery({ query: '(min-width: 768px)' })
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalIsOpen]);

  return (
    <div className={`${window.location.pathname === '/venues' ? '' : 'sticky'} top-0 z-40 -mb-20`}>
      <div className='flex flex-row text-white pt-8 '>
      <Link to='/' component={RouterLink} >
        <img src={everRentLogo} className='w-36 ml-10 '/>
        </Link>

      {screenWidth? 
        <div className='flex flex-row  ml-auto mr-10 text-base font-calibreSB text-white'>

             <Link to='/about' component={RouterLink} >
             <div className={`${window.location.pathname === '/about' ? 'underline' : ''} `}>ABOUT</div>
             </Link>
    
             <Link to='/services' component={RouterLink} >
             <div className={`${window.location.pathname === '/services' ? 'underline' : ''} pl-5`}>SERVICES</div>
             </Link>
             <Link to='/venues' component={RouterLink} >
             <div className={`${window.location.pathname === '/venues' ? 'underline' : ''} pl-5`}>VENUES</div>
             </Link>
             <Link to='/inquire' component={RouterLink} >
             <div className={`${window.location.pathname === '/inquire' ? 'underline' : ''} pl-5`}>CONTACT</div>
             </Link>
  
        </div>
      :
      
      <div className='flex flex-row  ml-auto mr-9 text-sm '>
        {modalVisible?
         <CloseIcon      id="basic-button"
         aria-controls={open ? 'basic-menu' : undefined}
         aria-haspopup="true"
         aria-expanded={open ? 'true' : undefined}
         onClick={modalToggle}
         style={{cursor: 'pointer', }}/>
         :
         <MenuIcon      id="basic-button"
         aria-controls={open ? 'basic-menu' : undefined}
         aria-haspopup="true"
         aria-expanded={open ? 'true' : undefined}
         onClick={openModal}
         style={{cursor: 'pointer'}}/>
         }
         

        </div>

      }
      {modalIsOpen && <MobileMenu closeModal={closeModal}/>}
      </div>
      <div>

      </div>
    </div>
  )
}

export default Navbar