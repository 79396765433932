import React, {useEffect, useState} from 'react'
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'
import everRentLogo from '../assets/everrent_logo.svg'
import { useMediaQuery } from 'react-responsive'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

function MobileMenu({closeModal}) {

  const [opened, setOpened] = useState(false)

  useEffect(() => {
    setOpened(true)
  })
  return (
    <div className='w-[100vw] h-screen fixed bg-black text-white top-0 bottom-0 left-0 right-0 overflow-hidden '>
   
    <div className='text-white flex flex-row'>
       <Link to='/' component={RouterLink} >
        <img src={everRentLogo} className='w-36 ml-10 pt-8 '/>
        </Link>
        <div className='ml-auto pt-7 pr-9'>
        <CloseIcon      id="basic-button"
        //  aria-controls={open ? 'basic-menu' : undefined}
         aria-haspopup="true"
        //  aria-expanded={open ? 'true' : undefined}
         onClick={closeModal}
         style={{cursor: 'pointer' }}/>
         </div>
      </div>
 
    <div className={`mx-auto my-[20vh] text-center transition-opacity ease-in duration-700 ${opened? 'opacity-100' : 'opacity-0'}`}>

    <Link to='/about' component={RouterLink} >
      <div className={`text-4xl font-calibreReg `}>ABOUT</div>
     </Link>

     <Link to='/services' component={RouterLink} >
      <div className='text-4xl font-calibreReg'>SERVICES</div>
     </Link>
     <Link to='/venues' component={RouterLink} >
      <div className='text-4xl font-calibreReg'>VENUES</div>
     </Link>
     <Link to='/inquire' component={RouterLink} >
      <div className='text-4xl font-calibreReg'>CONTACT</div>
     </Link>



</div>
    </div>
  )
}

export default MobileMenu