import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
    // firstName: yup.string().max(50).required('Required Field'),
    // lastName: yup.string().max(50).required('Required Field'),
  })
  .required();

const Inquire = () => {
  let selectedLocation = "testt";
  selectedLocation = localStorage.getItem("locationInquire");

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      company: "",
      number: "",
      title: "",
      type: "",
      notes: "",
    },
  });
  const onSubmit = (data) => {
    sendEmail("williamjwelling@gmail.com", data);
    console.log(data);
  };
  const sendEmail = async (targetEmail, data) => {
    axios
      .post("http://localhost:8000/email", {
        location: selectedLocation,
        email: targetEmail,
        data: data,
      })
      .then((response) => {
        console.log(response.data);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" text-white">
      <Navbar />
      <div className="text-center text-5xl md:text-6xl font-calibreBold mt-32 mb-10 lg:mb-20">
        Contact Form
      </div>
      <div className="text-center text-2xl md:text-3xl font-calibreBold mt-10 mb-10">
        {selectedLocation}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className=" text-white">
        {/* register your input into the hook by invoking the "register" function */}

        <div className="flex flex-col  lg:flex-row w-full justify-center mx-auto ">
          {/* left col vvv */}
          <div className="flex flex-col mr-[5vw] ml-5 sm:ml-10 lg:mr-28 ">
            <div class="flex flex-col border-b border-white mb-10 ">
              <label className="text-2xl font-calibreSB ">Name</label>
              <input
                {...register("name", { required: true })}
                class=" lg:w-96 focus:ring-transparent appearance-none bg-transparent border-none  text-white py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder=""
                aria-label="Full name"
              />
            </div>

            <div class="flex flex-col border-b border-white mb-10 ">
              <label className="text-2xl font-calibreSB ">Phone</label>
              <input
                {...register("phone", { required: true })}
                class="lg:w-96 focus:ring-transparent appearance-none bg-transparent border-none text-white  py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder=""
                aria-label="Full name"
              />
            </div>

            <div class="flex flex-col border-b border-white mb-10 ">
              <label className="text-2xl font-calibreSB ">Company</label>
              <input
                {...register("company", { required: true })}
                class="lg:w-96 focus:ring-transparent appearance-none bg-transparent border-none text-white  py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder=""
                aria-label="Full name"
              />
            </div>

            <div class="flex flex-col border-b border-white mb-10 ">
              <label className="text-2xl font-calibreSB ">
                Number of people
              </label>
              <input
                {...register("amount", { required: true })}
                class="lg:w-96 focus:ring-transparent appearance-none bg-transparent border-none text-white  py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder=""
                aria-label="Full name"
              />
            </div>
          </div>

          {/* right col vvv */}
          <div className="flex flex-col mr-[5vw] lg:mr-0  ml-5 sm:ml-10 lg:ml-20">
            <div class="flex flex-col border-b border-white mb-10 ">
              <label className="text-2xl font-calibreSB ">Email </label>
              <input
                {...register("email", { required: true })}
                class="autofill:!bg-yellow-200 ... lg:w-96 focus:ring-transparent appearance-none bg-transparent border-none  text-white py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder=""
                aria-label="Full name"
              />
              {errors.email?.type === "required" && (
                <p className="text-white -mt-8e text-xs">Email is required</p>
              )}
            </div>

            <div class="flex flex-col border-b border-white mb-10 ">
              <label className="text-2xl font-calibreSB ">Title</label>
              <input
                {...register("title", { required: true })}
                class="  lg:w-96 focus:ring-transparent appearance-none bg-transparent border-none  text-white py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder=""
                aria-label="Full name"
              />
            </div>
            <div class="flex flex-col border-b border-white mb-10 ">
              <label className="text-2xl font-calibreSB ">Type</label>
              <input
                {...register("type", { required: true })}
                class="  lg:w-96 focus:ring-transparent appearance-none bg-transparent border-none  text-white py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder=""
                aria-label="Full name"
              />
            </div>
          </div>
        </div>
        <div className="text-2xl font-calibreSB text-center">Notes</div>
        <div className="border-white w-3/4 lg:w-1/2 h-40 border mx-auto mb-10">
          <textarea
            {...register("notes", { required: true })}
            className="resize-none w-full h-40 bg-transparent outline-0 border-0 focus:ring-0 border-white focus:ring-transparent appearance-none"
          ></textarea>
        </div>

        <div className="w-60 h-8 bg-white grid place-items-center mx-auto mb-10">
          <input
            type="submit"
            className="text-center text-black text-l cursor-pointer"
          />
        </div>
        {/* <div class="flex flex-col border-b border-white mb-10 ">
          <label>Email</label>
          <input class=" w-96  focus:ring-transparent appearance-none bg-transparent border-none  text-white py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="" aria-label="Full name"/>
        <textarea className='bg-black'></textarea>
        </div> */}
      </form>
    </div>
  );
};

export default Inquire;
