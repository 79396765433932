import React from 'react'
import { BrowserRouter as Router, Link, Link as RouterLink } from 'react-router-dom'
import everRentLogo from '../assets/everrent_logo.svg'
import useLocationStore from '../stateManagement/LocationState'


function Footer() {

  const store = useLocationStore()
  const testLocationsArray = ["New York City", "Brooklyn",
   "Long Island", "Upstate", "Brooklyn", "Long Island", "Upstate"
  ]
  const locationsArray = localStorage.getItem('locations').split(',')
  return (
    <div className='bottom-0 h-36  z-50 text-white mt-10'>
      <hr className='ml-10 mr-10'/>
      <div className='flex flex-row text-white pt-5 pb-5'>
      {/* <Link to='/' component={RouterLink} > */}
        {/* <img src={everRentLogo} className='w-36 ml-5 -mt-16 hidden md:flex'/> */}
        {/* </Link> */}
        <div className='flex flex-row  ml-auto mr-10 font-calibreSB text-xs '>
          <div className={`flex flex-col pl-20  ${locationsArray.length > 4? 'mr-52' : 'mr-28'}`}>
          <div className='mb-3'>VENUES</div>
          <div className='flex flex-col flex-wrap h-24 '>
        {locationsArray.map((i, index) => {
          return (
            <Link key={index} to='/venues' component={RouterLink} onClick={() => store.setLocationIndex(index) } >
              <div className='mr-4'>{i}</div>
            </Link>

          )
        })}
          </div>
          </div>

          <div className='flex flex-col '>
            <div className='mb-3'>COMPANY</div>
            <Link to='/story' component={RouterLink} >
              <div className=''>Our Story</div>          
            </Link>
            
            <div className=''>List Your Spaces</div>
            <div className=''>Terms</div>
            <div className=''>Privacy</div>
          </div>
          <div className='flex flex-col pl-28'>
            <div className='mb-3'>CONTACT US</div>
            <div className=''>info@ever.rent.com</div>
            <div className=''>646-580-3419</div>
          </div>
          
          {/* </Link> */}
        </div>
      </div>
      
    </div>
  )
}

export default Footer