import React from 'react'

function LocationsNavbar({locations}) {
  return (
    <div className='text-white flex flex-row sticky top-14 z-40 justify-center gap-5'>
      
      {locations.map((i, index) => {
      return (
<p className='text-sm'>{i}</p>
    )
    })}
    </div>
  )
}

export default LocationsNavbar