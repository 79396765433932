import React, { useEffect, useState, Component } from "react";
import axios from "axios";
import useLocationStore from "../stateManagement/LocationState";
import {
  BrowserRouter as Router,
  Link,
  Link as RouterLink,
} from "react-router-dom";
import ReactDOM from "react-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Waypoint } from "react-waypoint";
import backArrow from "../assets/arrow_back_rounded.svg";
import { useMediaQuery } from "react-responsive";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { set } from "react-hook-form";
import { useParams } from "react-router-dom";
// import { Carousel } from 'flowbite-react';

function LocationPage() {
  const indicatorStyles = {
    backgroundColor: "blue",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    margin: "0 8px",
  };
  const store = useLocationStore();
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [venue, setVenue] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [photo1, setPhoto1] = useState(null);
  const [photo2, setPhoto2] = useState(null);
  const [carousel, setCarousel] = useState([]);
  const [bottomCarousel, setBottomCarousel] = useState([]);
  const [optionOnePhotos, setOptionOnePhotos] = useState([]);
  const [optionTwoPhotos, setOptionTwoPhotos] = useState([]);
  const [optionThreePhotos, setOptionThreePhotos] = useState([]);
  const [visable, setVisable] = useState(false);
  const [visable1, setVisable1] = useState(false);
  const [visable2, setVisable2] = useState(false);
  const [visable3, setVisable3] = useState(false);
  const [visable4, setVisable4] = useState(false);
  const [visable5, setVisable5] = useState(false);
  const [visable6, setVisable6] = useState(false);
  const [visable7, setVisable7] = useState(false);
  const [visable8, setVisable8] = useState(false);
  const [visableOption, setVisableOption] = useState("overview");
  const [stickyButton, setStickyButton] = useState(false);
  const locationID = store.locationID;
  const screenWidth = useMediaQuery({ query: "(min-width: 768px)" });
  const { id } = useParams();

  const getVenue = async () => {
    setLoading(false);
    axios.get(`${API_URL}/api/venues/${id}?populate=*`).then((response) => {
      // console.log(response.data.data.attributes)
      const photo1URL =
        response?.data?.data?.attributes?.photo1?.data?.attributes?.url || null;
      const photo2URL =
        response?.data?.data?.attributes?.photo2?.data?.attributes?.url || null;
      setPhoto1(photo1URL);
      setPhoto2(photo2URL);
      setVenue(response.data.data.attributes);
      setCarousel(response.data.data.attributes.carousel_top.data);
      setBottomCarousel(response.data.data.attributes.carousel_bottom.data);
      setLoading(true);
      setOptionOnePhotos(response.data.data.attributes.option_one_photos.data);
      setOptionTwoPhotos(response.data.data.attributes.option_two_photos.data);
      setOptionThreePhotos(
        response.data.data.attributes.option_three_photos.data
      );
    });
  };

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);

    var headerOffset = 85;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  let photoURLs = [];
  if (photos?.length > 0) {
    photoURLs = photos.map((i) => {
      return i.attributes.url;
    });
  }

  let bottomPhotos = photoURLs.slice(2);

  let carouselPhotoURLs = [];
  if (carousel?.length > 0) {
    carouselPhotoURLs = carousel.map((i) => {
      return i.attributes.url;
    });
  }

  let bottomCarouselPhotoURLs = [];
  if (bottomCarousel?.length > 0) {
    bottomCarouselPhotoURLs = bottomCarousel.map((i) => {
      return i.attributes.url;
    });
  }

  let optionOnePhotosURLs = [];
  if (optionOnePhotos?.length > 0) {
    optionOnePhotosURLs = optionOnePhotos.map((i) => {
      return i.attributes.url;
    });
  }

  let optionTwoPhotosURLs = [];
  if (optionTwoPhotos?.length > 0) {
    optionTwoPhotosURLs = optionTwoPhotos.map((i) => {
      return i.attributes.url;
    });
  }

  let optionThreePhotosURLs = [];
  if (optionThreePhotos?.length > 0) {
    optionThreePhotosURLs = optionThreePhotos.map((i) => {
      return i.attributes.url;
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getVenue();
  }, []);

  return (
    <div className="bg-black  min-h-screen text-white ">
      {loading ? (
        <>
          {visable ? null : (
            // <div className={` transition-opacity ease-in duration-700 ${visable? 'opacity-0' : 'opacity-100'}`}>
            <Navbar />
          )}

          <Waypoint onEnter={() => setVisable(false)}>
            <div className="top-10"></div>
          </Waypoint>
          <Waypoint
            onEnter={() => setStickyButton(false)}
            onLeave={() => setStickyButton(true)}
            topOffset={-80}
          >
            <div className="mb-10">
              <Carousel
                showThumbs={false}
                infiniteLoop={true}
                showStatusshowStatus={false}
                showStatus={false}
                // showIndicators={false}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const defStyle = {
                    outline: "none",
                    display: "inline-flex",
                    marginLeft: 20,
                    marginBottom: "20px",
                    color: "white",
                    cursor: "pointer",
                    backgroundColor: "black",
                    borderRadius: "50%",
                    height: "12px",
                    width: "12px",
                  };
                  const style = isSelected
                    ? { ...defStyle, backgroundColor: "white" }
                    : { ...defStyle };
                  return (
                    <span
                      style={style}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      aria-label={`${label} ${index + 1}`}
                    >
                      <div style={style}></div>
                    </span>
                  );
                }}
                renderArrowPrev={(clickHandler, hasPrev) => {
                  return (
                    <div
                      className={`${
                        hasPrev ? "absolute" : "hidden"
                      } w-14 top-0 bottom-0 left-0 flex  items-center p-3  cursor-pointer z-20`}
                      onClick={clickHandler}
                    >
                      <img
                        src={backArrow}
                        className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  ml-6 fill-slate-100 cursor-pointer"
                      />
                    </div>
                  );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  return (
                    <div
                      className={`${
                        hasNext ? "absolute" : "hidden"
                      } w-14 top-0 bottom-0 right-0 flex  items-center p-3  cursor-pointer z-20`}
                      onClick={clickHandler}
                    >
                      <img
                        src={backArrow}
                        className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  mr-6 fill-slate-100 cursor-pointer -rotate-180 "
                      />
                    </div>
                  );
                }}
              >
                {carouselPhotoURLs.map((i, index) => {
                  return (
                    <div key={index}>
                      <img
                        className="h-screen w-screen object-cover"
                        src={`${i}`}
                      />
                      {/* <p >Legend 1</p> */}
                    </div>
                  );
                })}
              </Carousel>

              {screenWidth ? (
                <div
                  className={` transition-opacity ease-in duration-500 ${
                    visable ? "opacity-0" : "opacity-100"
                  }`}
                >
                  {/* // <div className='w-[96vw]'> */}
                  {/* <div className='flex flex-col -mt-44 '> */}

                  {/* <div className={`absolute leading-loose text-white my-auto flex flex-row align-middle space-x-4 transition-opacity ease-in duration-500 ${visable? 'opacity-0' : 'opacity-100'}`}>
        <p className='text-white font-domaine w-2/3 text-left text-3xl md:text-5xl whitespace-nowrap ml-5'>{venue.name}</p>
      </div> */}
                  <div
                    className={`absolute leading-loose text-white w-[96vw] flex flex-row align-middle  mx-auto justify-center space-x-4 -mt-80 transition-transform ease-in transform duration-1000  ${
                      visable ? "translate-y-0" : "translate-y-10"
                    }`}
                  >
                    <p className="text-white font-domaine text-3xl md:text-5xl whitespace-nowrap -mr-[4vw] ">
                      {venue.name}
                    </p>
                  </div>
                  <div
                    className={`absolute  text-white   left-1/2    justify-center -mt-40  transition-transform ease-in transform duration-1000 ${
                      visable ? "translate-y-0" : "translate-y-10"
                    }`}
                  >
                    <Link to="/inquire" component={RouterLink}>
                      {/* <div onClick={()=>{localStorage.setItem('locationInquire', venue.name)}} className={`cursor-pointer absolute leading-loose mt-2 right-10  w-60 h-8 bg-white grid place-items-center transition-opacity ease-in duration-500 ${visable? 'opacity-0' : 'opacity-100'}`}>
        <div className='text-center text-black text-l'>INQUIRE</div>
      </div> */}

                      <div
                        onClick={() => {
                          localStorage.setItem("locationInquire", venue.name);
                        }}
                        className="absolute w-80 md:w-72 h-12 md:h-10 bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      >
                        <div className="text-center text-black text-lg md:text-base my-auto md:mt-2 font-calibreSB">
                          INQUIRE
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                <div
                  className={`absolute leading-loose text-white w-screen flex flex-row  bg-black transition-opacity ease-in duration-500 ${
                    visable ? "opacity-100" : "opacity-100"
                  }`}
                >
                  <p className="-mt-[15vh] ml-10 text-white font-domaine text-left text-2xl  sm:text-3xl md:text-5xl whitespace-nowrap ">
                    {venue.name}
                  </p>
                </div>
              )}
            </div>
          </Waypoint>

          {screenWidth ? (
            <div className="top-0 sticky bg-black bg-opacity-90 h-18 lg:h-16 z-50">
              <Waypoint>
                <div
                  className={`flex flex-row flex-auto justify-evenly align-middle mt-0  text-white  z-40  transition-opacity ease-in duration-500 ${
                    visable ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <div className="my-auto font-domaine md:text-xl xl:text-2xl ml-4 mt-4 xl:ml-8 mr-auto whitespace-nowrap ">
                    {venue.name}
                  </div>

                  <Link to="/inquire" component={RouterLink}>
                    <div
                      onClick={() => {
                        localStorage.setItem("locationInquire", venue.name);
                      }}
                      className="cursor-pointer  mt-3 leading-loose my-auto  mr-4 xl:mr-8  ml-auto md:w-52 xl:w-60 h-8 border border-zinc-400 grid place-items-center"
                    >
                      <div className="text-center text-zinc-400 text-base font-calibreSB">
                        INQUIRE
                      </div>
                    </div>
                  </Link>
                </div>
              </Waypoint>
              <div
                className={`z-50 flex flex-row justify-center h-16 ml-auto mr-auto gap-3 xl:gap-5 text-base xl:text-base font-calibreReg   flex-nowrap -mt-5 lg:-mt-14 transition-opacity ease-in duration-500 ${
                  visable ? "opacity-100" : "opacity-0"
                }`}
              >
                <div
                  className={`cursor-pointer whitespace-nowrap mt-7 ${
                    visableOption == "overview" ? "text-white" : "text-zinc-400"
                  }`}
                  onClick={() => handleClickScroll(`overview`)}
                >
                  Overview
                </div>
                <div
                  className={`cursor-pointer whitespace-nowrap mt-7 ${
                    visableOption == "details" ? "text-white" : "text-zinc-400"
                  }`}
                  onClick={() => handleClickScroll(`details`)}
                >
                  Property Details
                </div>
                {/* <div className={`cursor-pointer whitespace-nowrap mt-7 ${visableOption == 'p' ? 'text-white': 'text-zinc-400'}`}>Potential Uses</div>
          <div className={`cursor-pointer whitespace-nowrap mt-7 ${visableOption == 'p' ? 'text-white': 'text-zinc-400'}`}>Features</div> */}
                <div
                  className={`cursor-pointer whitespace-nowrap mt-7 ${
                    visableOption == "option1" ? "text-white" : "text-zinc-400"
                  }`}
                  onClick={() => handleClickScroll(`${venue.option_one_label}`)}
                >
                  {venue.option_one_label}
                </div>
                <div
                  className={`cursor-pointer whitespace-nowrap mt-7 ${
                    visableOption == "option2" ? "text-white" : "text-zinc-400"
                  }`}
                  onClick={() => handleClickScroll(`${venue.option_two_label}`)}
                >
                  {venue.option_two_label}
                </div>
                <div
                  className={`cursor-pointer whitespace-nowrap mt-7 ${
                    visableOption == "option3" ? "text-white" : "text-zinc-400"
                  }`}
                  onClick={() =>
                    handleClickScroll(`${venue.option_three_label}`)
                  }
                >
                  {venue.option_three_label}
                </div>
              </div>
            </div>
          ) : (
            <Waypoint>
              <div className="flex  flex-col  mt-10 justify-center">
                {/* <div className='my-auto font-domaine text-4xl mx-auto'>{venue.name}</div> */}

                <Link to="/inquire" component={RouterLink}>
                  <div
                    onClick={() => {
                      localStorage.setItem("locationInquire", venue.name);
                    }}
                    className="cursor-pointer w-80 md:w-72 h-12 md:h-10  leading-loose mt-4 mx-auto  border border-zinc-400 grid place-items-center"
                  >
                    <div className="text-center text-zinc-400 text-l font-calibreSB my-auto">
                      INQUIRE
                    </div>
                  </div>
                </Link>
              </div>
            </Waypoint>
          )}

          {/* <div onClick={() => console.log( photoURLs)}>click</div> */}
          <div className="text-white flex flex-col justify-center mt-0 md:mt-10">
            <Waypoint
              onEnter={() => {
                setVisable(true);
                setVisableOption("overview");
              }}
            >
              {/* <div className={`text-white font-domaine mt-10 mb-20 ml-auto mr-auto text-lg sm:text-2xl lg:text-3xl w-3/4 md:w-3/5 transition-all ease-in duration-500 ${visable? 'opacity-100 ' : 'opacity-0 '}`} id='overview'>  */}

              <div
                className={`text-white font-domaine mt-10 mb-20 ml-auto mr-auto text-lg sm:text-2xl lg:text-3xl w-3/4 md:w-3/5 transition-all ease-in duration-500 ${
                  visable
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-3"
                }`}
                id="overview"
              >
                <ReactMarkdown
                  className="leading-snug"
                  children={venue.property_details1}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            </Waypoint>
            <Waypoint
              onEnter={() => {
                setVisable1(true);
              }}
              bottomOffset={100}
            />
            {photo1 != null && (
              <div className="flex justify-center ">
                <img
                  className={`w-screen md:w-3/4  object-center transition-opacity ease-in duration-500 ${
                    visable1 ? "opacity-100" : "opacity-0"
                  }`}
                  src={`${photo1}`}
                />
              </div>
            )}

            <Waypoint
              onEnter={() => {
                setVisableOption("details");
                setVisable2(true);
              }}
              bottomOffset={100}
            >
              <div
                className={`w-3/5 ml-auto  mr-auto mt-20 mb-10 transition-opacity ease-in duration-500 ${
                  visable2 ? "opacity-100" : "opacity-0"
                }`}
                id="details"
              >
                <div className="text-lg font-calibreSB text-[#999999] mb-1">
                  Property Details
                </div>
                {/* <hr className='h-px border-none bg-[#999999] opacity-50'/> */}
                <div className=" mt-5 gap-y-4 flex flex-col">
                  <div className="font-calibreReg ">
                    Location: {venue.location}
                    <p></p>
                  </div>
                  <div className="font-calibreReg whitespace-nowrap">
                    Property Type:{" "}
                    <span className="font-calibreReg whitespace-nowrap">
                      {venue.property_type}
                    </span>
                  </div>
                  <div className="font-calibreReg whitespace-nowrap">
                    {" "}
                    Hourly Rate: {venue.hourly_rate}
                    <p></p>
                  </div>
                  <div className="mt-4 text-xs italic">
                    Preferred Vendors upon request. Contract & terms to be
                    presented at time of confirmation.
                  </div>
                </div>
              </div>
            </Waypoint>

            <Waypoint
              onEnter={() => {
                setVisable3(true);
              }}
              bottomOffset={100}
            />
            {photo2 != null && (
              <div
                className={`flex justify-center transition-opacity ease-in duration-500 ${
                  visable3 ? "opacity-100" : "opacity-0"
                } mt-10 mb-10`}
              >
                <img
                  className="w-screen md:w-3/4 object-center"
                  src={`${photo2}`}
                />
              </div>
            )}

            <Waypoint
              onEnter={() => {
                setVisable4(true);
              }}
              bottomOffset={100}
            />
            {venue.property_details2 && (
              <div
                className={`text-white font-domaine mt-10 mb-10 ml-auto mr-auto text-lg sm:text-2xl lg:text-3xl w-3/5 transition-opacity ease-in duration-500 ${
                  visable4 ? "opacity-100" : "opacity-0"
                }`}
                id="overview"
              >
                <ReactMarkdown
                  className="leading-snug"
                  children={venue.property_details2}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            )}

            {venue.option_one_label && (
              <Waypoint
                onEnter={() => {
                  setVisableOption("option1");
                  setVisable5(true);
                }}
                bottomOffset={100}
              >
                <div
                  className={`w-screen md:w-3/4 mx-auto z-20 transition-opacity mt-10  ease-in duration-500 ${
                    visable5 ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <Carousel
                    showThumbs={false}
                    infiniteLoop={true}
                    showStatusshowStatus={false}
                    showStatus={false}
                    // showIndicators={false}
                    renderIndicator={(
                      onClickHandler,
                      isSelected,
                      index,
                      label
                    ) => {
                      const defStyle = {
                        outline: "none",
                        display: "inline-flex",
                        marginLeft: 20,
                        marginBottom: "20px",
                        color: "white",
                        cursor: "pointer",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        height: "12px",
                        width: "12px",
                      };
                      const style = isSelected
                        ? { ...defStyle, backgroundColor: "white" }
                        : { ...defStyle };
                      return (
                        <span
                          style={style}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}
                          value={index}
                          key={index}
                          role="button"
                          tabIndex={0}
                          aria-label={`${label} ${index + 1}`}
                        >
                          <div style={style}></div>
                        </span>
                      );
                    }}
                    renderArrowPrev={(clickHandler, hasPrev) => {
                      return (
                        <div
                          className={`${
                            hasPrev ? "absolute" : "hidden"
                          }  w-14 top-0 bottom-0 left-0 flex  items-center p-3  cursor-pointer z-20`}
                          onClick={clickHandler}
                        >
                          <img
                            src={backArrow}
                            className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  ml-3 fill-slate-100 cursor-pointer"
                          />
                        </div>
                      );
                    }}
                    renderArrowNext={(clickHandler, hasNext) => {
                      return (
                        <div
                          className={`${
                            hasNext ? "absolute" : "hidden"
                          } w-14 top-0 bottom-0 right-0 flex  items-center p-3  cursor-pointer z-20`}
                          onClick={clickHandler}
                        >
                          <img
                            src={backArrow}
                            className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  mr-6 fill-slate-100 cursor-pointer -rotate-180 "
                          />
                        </div>
                      );
                    }}
                  >
                    {optionOnePhotosURLs.map((i, index) => {
                      return (
                        <div key={index}>
                          <img
                            className="w-screen md:w-3/4 h-96 md:h-[80vh] object-cover"
                            src={`${i}`}
                          />
                          {/* <p >Legend 1</p> */}
                        </div>
                      );
                    })}
                  </Carousel>

                  <div
                    className="w-5/6 mx-auto text-lg font-calibreSB text-[#999999] mb-1 md:mb-5 mt-20"
                    id={`${venue.option_one_label}`}
                  >
                    {venue.option_one_label}
                  </div>
                  <div className="flex flex-row justify-center">
                    <div class="w-5/6  grid grid-cols-1 md:grid-cols-2  gap-x-10 gap-y-6 lg:gap-x-20  ml-0 mb-20">
                      {/* <div class="w-3/4  md:ml-0 grid grid-cols-1 md:grid-cols-2  gap-3 md:gap-4  ml-96 mb-20"> */}

                      {venue.option_one_details?.map((i) => {
                        return (
                          <div className="font-calibreReg -indent-2.5 ml-2.5">
                            - {i}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Waypoint>
            )}

            {venue.option_two_label && (
              <Waypoint
                onEnter={() => {
                  setVisableOption("option2");
                  setVisable6(true);
                }}
                bottomOffset={100}
              >
                <div
                  className={`w-screen md:w-3/4 mx-auto z-20 transition-opacity ease-in duration-500 ${
                    visable6 ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <Carousel
                    showThumbs={false}
                    infiniteLoop={true}
                    showStatusshowStatus={false}
                    showStatus={false}
                    // showIndicators={false}
                    renderIndicator={(
                      onClickHandler,
                      isSelected,
                      index,
                      label
                    ) => {
                      const defStyle = {
                        outline: "none",
                        display: "inline-flex",
                        marginLeft: 20,
                        marginBottom: "20px",
                        color: "white",
                        cursor: "pointer",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        height: "12px",
                        width: "12px",
                      };
                      const style = isSelected
                        ? { ...defStyle, backgroundColor: "white" }
                        : { ...defStyle };
                      return (
                        <span
                          style={style}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}
                          value={index}
                          key={index}
                          role="button"
                          tabIndex={0}
                          aria-label={`${label} ${index + 1}`}
                        >
                          <div style={style}></div>
                        </span>
                      );
                    }}
                    renderArrowPrev={(clickHandler, hasPrev) => {
                      return (
                        <div
                          className={`${
                            hasPrev ? "absolute" : "hidden"
                          }  w-14 top-0 bottom-0 left-0 flex  items-center p-3  cursor-pointer z-20`}
                          onClick={clickHandler}
                        >
                          <img
                            src={backArrow}
                            className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  ml-3 fill-slate-100 cursor-pointer"
                          />
                        </div>
                      );
                    }}
                    renderArrowNext={(clickHandler, hasNext) => {
                      return (
                        <div
                          className={`${
                            hasNext ? "absolute" : "hidden"
                          } w-14 top-0 bottom-0 right-0 flex  items-center p-3  cursor-pointer z-20`}
                          onClick={clickHandler}
                        >
                          <img
                            src={backArrow}
                            className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  mr-6 fill-slate-100 cursor-pointer -rotate-180 "
                          />
                        </div>
                      );
                    }}
                  >
                    {optionTwoPhotosURLs.map((i, index) => {
                      return (
                        <div key={index}>
                          <img
                            className="w-screen md:w-3/4 h-96 md:h-[80vh] object-cover"
                            src={`${i}`}
                          />
                          {/* <p >Legend 1</p> */}
                        </div>
                      );
                    })}
                  </Carousel>
                  <div
                    className="w-5/6 mx-auto text-lg font-calibreSB text-[#999999] mb-1 md:mb-5 mt-20 "
                    id={`${venue.option_two_label}`}
                  >
                    {venue.option_two_label}
                  </div>
                  <div className="flex flex-row justify-center">
                    <div class="w-5/6  grid grid-cols-1 md:grid-cols-2  gap-x-10 gap-y-6 lg:gap-x-20  ml-0 mb-20">
                      {/* <div class="w-3/4  md:ml-0 grid grid-cols-1 md:grid-cols-2  gap-3 md:gap-4  ml-96 mb-20"> */}
                      {venue.option_two_details?.map((i) => {
                        return (
                          <div className="font-calibreReg -indent-2.5 ml-2.5">
                            - {i}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Waypoint>
            )}

            {venue.option_three_label && (
              <Waypoint
                onEnter={() => {
                  setVisableOption("option3");
                  setVisable7(true);
                }}
                bottomOffset={100}
              >
                <div
                  className={`w-screen md:w-3/4 mx-auto z-20 transition-opacity transition- ease-in duration-500 ${
                    visable7 ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <Carousel
                    showThumbs={false}
                    infiniteLoop={true}
                    showStatusshowStatus={false}
                    showStatus={false}
                    // showIndicators={false}
                    renderIndicator={(
                      onClickHandler,
                      isSelected,
                      index,
                      label
                    ) => {
                      const defStyle = {
                        outline: "none",
                        display: "inline-flex",
                        marginLeft: 20,
                        marginBottom: "20px",
                        color: "white",
                        cursor: "pointer",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        height: "12px",
                        width: "12px",
                      };
                      const style = isSelected
                        ? { ...defStyle, backgroundColor: "white" }
                        : { ...defStyle };
                      return (
                        <span
                          style={style}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}
                          value={index}
                          key={index}
                          role="button"
                          tabIndex={0}
                          aria-label={`${label} ${index + 1}`}
                        >
                          <div style={style}></div>
                        </span>
                      );
                    }}
                    renderArrowPrev={(clickHandler, hasPrev) => {
                      return (
                        <div
                          className={`${
                            hasPrev ? "absolute" : "hidden"
                          }  w-14 top-0 bottom-0 left-0 flex  items-center p-3  cursor-pointer z-20`}
                          onClick={clickHandler}
                        >
                          <img
                            src={backArrow}
                            className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  ml-3 fill-slate-100 cursor-pointer"
                          />
                        </div>
                      );
                    }}
                    renderArrowNext={(clickHandler, hasNext) => {
                      return (
                        <div
                          className={`${
                            hasNext ? "absolute" : "hidden"
                          } w-14 top-0 bottom-0 right-0 flex  items-center p-3  cursor-pointer z-20`}
                          onClick={clickHandler}
                        >
                          <img
                            src={backArrow}
                            className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  mr-6 fill-slate-100 cursor-pointer -rotate-180 "
                          />
                        </div>
                      );
                    }}
                  >
                    {optionThreePhotosURLs.map((i, index) => {
                      return (
                        <div key={index}>
                          <img
                            className="w-screen md:w-3/4 h-96 md:h-[80vh] object-cover"
                            src={`${i}`}
                          />
                          {/* <p >Legend 1</p> */}
                        </div>
                      );
                    })}
                  </Carousel>

                  <div
                    className="w-5/6 mx-auto text-lg font-calibreSB text-[#999999] mb-1 md:mb-5 mt-20"
                    id={`${venue.option_three_label}`}
                  >
                    {venue.option_three_label}
                  </div>

                  <div className="flex flex-row justify-center">
                    <div class="w-5/6 grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-6 lg:gap-x-20  ml-0 mb-20">
                      {venue.option_three_details?.map((i) => {
                        return (
                          <div className="font-calibreReg -indent-2.5 ml-2.5">
                            - {i}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Waypoint>
            )}

            <Waypoint
              onEnter={() => {
                setVisable8(true);
              }}
              bottomOffset={100}
            />
            <div
              className={`flex w-screen md:w-3/4  flex-col  mx-auto mb-20 transition-opacity ease-in duration-500 ${
                visable8 ? "opacity-100" : "opacity-0"
              }`}
            >
              <Carousel
                showThumbs={false}
                infiniteLoop={true}
                showStatusshowStatus={false}
                showStatus={false}
                // showIndicators={false}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const defStyle = {
                    outline: "none",
                    display: "inline-flex",
                    marginLeft: 20,
                    marginBottom: "20px",
                    color: "white",
                    cursor: "pointer",
                    backgroundColor: "black",
                    borderRadius: "50%",
                    height: "12px",
                    width: "12px",
                  };
                  const style = isSelected
                    ? { ...defStyle, backgroundColor: "white" }
                    : { ...defStyle };
                  return (
                    <span
                      style={style}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      aria-label={`${label} ${index + 1}`}
                    >
                      <div style={style}></div>
                    </span>
                  );
                }}
                renderArrowPrev={(clickHandler, hasPrev) => {
                  return (
                    <div
                      className={`${
                        hasPrev ? "absolute" : "hidden"
                      } w-14 top-0 bottom-0 left-0 flex  items-center p-3  cursor-pointer z-20`}
                      onClick={clickHandler}
                    >
                      <img
                        src={backArrow}
                        className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  ml-6 fill-slate-100 cursor-pointer"
                      />
                    </div>
                  );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  return (
                    <div
                      className={`${
                        hasNext ? "absolute" : "hidden"
                      } w-14 top-0 bottom-0 right-0 flex  items-center p-3  cursor-pointer z-20`}
                      onClick={clickHandler}
                    >
                      <img
                        src={backArrow}
                        className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  mr-6 fill-slate-100 cursor-pointer -rotate-180 "
                      />
                    </div>
                  );
                }}
              >
                {bottomCarouselPhotoURLs.map((i, index) => {
                  return (
                    <div key={index}>
                      <img
                        className="w-screen md:w-full h-96 md:h-[80vh] object-cover"
                        src={`${i}`}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
      {stickyButton == true && screenWidth == false && (
        <div className="fixed bottom-0 flex flex-row justify-center bg-black bg-opacity-50 h-18 lg:h-16 z-50 w-screen h-20">
          <Link to="/inquire" component={RouterLink}>
            <div
              onClick={() => {
                localStorage.setItem("locationInquire", venue.name);
              }}
              className=" w-80 cursor-pointer  md:w-72 h-12 md:h-10  leading-loose mt-5 mx-auto  border border-zinc-400 grid place-items-center "
            >
              <div className="text-center text-zinc-400 text-l ">INQUIRE</div>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}

export default LocationPage;
