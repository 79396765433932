import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import {
  BrowserRouter as Router,
  Link,
  Link as RouterLink,
} from "react-router-dom";
import backArrow from "../assets/arrow_back_rounded.svg";
import { Waypoint } from "react-waypoint";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useMediaQuery } from "react-responsive";

function Services() {
  const screenWidth = useMediaQuery({ query: "(min-width: 1280px)" });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-black text-white min-h-screen min-w-screen">
      <Navbar />
      {/* <div className='mt-28 text-5xl flex justify-center font-calibreReg'>Services</div> */}
      {/* <hr className='w-10/12  xl:w-[96vw] mx-auto mt-10' /> */}

      {screenWidth ? (
        <div>
          <div className="flex flex-row flex-auto justify-between w-[96vw] mx-auto mt-[35vh] h-68 ">
            {/* 1 vv */}
            <div className="flex flex-col  items-center w-1/3 h-64">
              <div className="text-center font-domaine text-4xl   ">
                Placemaking & Venue Development
              </div>

              <div className="  flex flex-col items-center  w-[30vw] ">
                <div className="text-center font-calibreReg text-xl leading-relaxed   mx-auto   ">
                  We transform underutilized assets into dynamic places,
                  leveraging years of creative intelligence to build lasting
                  value.
                </div>
              </div>
            </div>

            {/* 2 vv */}
            <div className="flex flex-col   items-center w-1/3 h-64">
              <div className="text-center font-domaine text-4xl">
                Operations & Management
              </div>

              <div className="flex flex-col items-center  w-[30vw]">
                <div className="text-center font-calibreReg text-xl leading-relaxed  mx-auto mb-10 ">
                  We manage the sales, operations and experience of venues to
                  maximize revenue and add to the overall dynamism of the asset.
                </div>
              </div>
            </div>

            {/* 3 vv */}
            <div className="flex flex-col  items-center w-1/3 h-64">
              <div className="text-center font-domaine text-4xl">
                Film, Photo & Events
              </div>

              <div className="flex flex-col items-center  w-[30vw]">
                <div className="text-center font-calibreReg text-xl leading-relaxed  mx-auto mb-10">
                  Our otherwise inaccessible locations bring to life even the
                  boldest creative visions for events, experiences, and film and
                  photo shoots.
                </div>
              </div>
            </div>

            {/* 3 ^^ */}
          </div>
          <div className="flex flex-row justify-between w-[85vw] mx-auto">
            <Link to="/inquire" component={RouterLink}>
              <div className="flex justify-center  w-[20vw]  h-12 border     border-white text-white hover:text-black hover:bg-white">
                <div className="text-center  text-sm  my-auto  font-calibreSB">
                  INQUIRE ABOUT PLACEMAKING
                </div>
              </div>
            </Link>

            <Link to="/inquire" component={RouterLink}>
              <div className="flex justify-center  w-[20vw]  h-12  border    border-white text-white hover:text-black hover:bg-white">
                <div className="text-center  text-sm  my-auto  font-calibreSB">
                  INQUIRE ABOUT VENUE DEVELOPMENT
                </div>
              </div>
            </Link>

            <Link to="/inquire" component={RouterLink}>
              <div className="flex justify-center  w-[20vw]  h-12  border    border-white text-white hover:text-black hover:bg-white">
                <div className="text-center  text-sm  my-auto  font-calibreSB">
                  BROWSE OUR LOCATIONS
                </div>
              </div>
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex flex-col  w-10/12 mx-auto mt-[25vh]">
          {/* 1 vv */}
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="text-center font-domaine text-2xl sm:text-3xl mb-5 ">
              Placemaking & Venue Development
            </div>

            <div className="flex flex-col items-center  lg:w-[40vw]">
              <div className="text-center font-calibreReg text-xl w-3/4 sm:w-2/3 mb-5 mx-auto">
                We transform underutilized assets into dynamic places,
                leveraging years of creative intelligence to build lasting
                value.
              </div>
              <div className="ml-0 lg:ml-auto">
                <Link to="/about" component={RouterLink}>
                  <div className="flex justify-center  w-[80vw] lg:w-[40vw] h-12 md:h-10   ml-auto mb-10 border border-white text-white hover:text-black hover:bg-white">
                    <div className="text-center  text-base  my-auto md:mt-2 font-calibreSB">
                      INQUIRE ABOUT PLACEMAKING
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <hr className="w-full mx-auto mb-10" />
          {/* 2 vv */}
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="text-center font-domaine text-2xl sm:text-3xl mb-5 ">
              Operations & Management
            </div>

            <div className="flex flex-col items-center  lg:w-[40vw]">
              <div className="text-center font-calibreReg text-xl w-3/4 sm:w-2/3 mb-5 mx-auto">
                We manage the sales, operations and experience of venues to
                maximize revenue and add to the overall dynamism of the asset.
              </div>
              <div className="ml-0 lg:ml-auto">
                <Link to="/about" component={RouterLink}>
                  <div className="flex justify-center  w-[80vw] lg:w-[40vw] h-12 md:h-10   ml-auto mb-10 border border-white text-white hover:text-black hover:bg-white">
                    <div className="text-center  text-base  my-auto md:mt-2 font-calibreSB">
                      INQUIRE ABOUT VENUE DEVELOPMENT
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <hr className="w-full mx-auto mb-10" />
          {/* 3 vv */}
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="text-center font-domaine text-2xl sm:text-3xl mb-5 ">
              Film, Photo & Events
            </div>

            <div className="flex flex-col items-center  lg:w-[40vw]">
              <div className="text-center font-calibreReg text-xl w-3/4 sm:w-2/3 mb-5 mx-auto">
                Our otherwise inaccessible locations bring to life even the
                boldest creative visions for events, experiences, and film and
                photo shoots.
              </div>
              <div className="ml-0 lg:ml-auto">
                <Link to="/locations" component={RouterLink}>
                  <div className="flex justify-center  w-[80vw] lg:w-[40vw] h-12 md:h-10   ml-auto mb-10 border border-white text-white hover:text-black hover:bg-white">
                    <div className="text-center  text-base  my-auto md:mt-2 font-calibreSB">
                      BROWSE OUR LOCATIONS
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {/* 3 ^^ */}
        </div>
      )}
    </div>
  );
}

export default Services;
