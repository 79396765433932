import {create}from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'


// type State = {
//   firstName: string
//   lastName: string
// }

// type Action = {
//   updateFirstName: (firstName: State['firstName']) => void
//   updateLastName: (lastName: State['lastName']) => void
// }
/// turn into store that saves clicked on location for the location page api call
// const useLocationStore = create((set) => ({
//   locationName: null,
//   locationID: 0,

//   setLocationName(text) {
//     set((state) => ({
//       ...state,
//       locationName: text,
//     }))
//   },
//   setLocationID(ID) {
//     set((state) => ({
//       ...state,
//       locationID: ID,
//     }))
//   }
// }))
// export default useLocationStore

 const useLocationStore = create(persist( (set, get) => ({
  locationName: null,
  locationID: 0,
  locationIndex: null,
  mobileMenu: false,

  setLocationName(text) {
    set((state) => ({
      ...state,
      locationName: text,
    }))
  },
  setLocationID(ID) {
    set((state) => ({
      ...state,
      locationID: ID,
    }))
  },
  setLocationIndex(index) {
    set((state) => ({
      ...state,
      locationIndex: index,
    }))
  }
    }),
    {
      name: 'location-storage', // name of the item in the storage (must be unique)
      // storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
)
export default useLocationStore
