import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import {
  BrowserRouter as Router,
  Link,
  Link as RouterLink,
} from "react-router-dom";
import backArrow from "../assets/arrow_back_rounded.svg";
import { Waypoint } from "react-waypoint";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

function About() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [photo1, setPhoto1] = useState();
  const [photo2, setPhoto2] = useState();
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [loading, setLoading] = useState(false);

  const getStrapi = async () => {
    axios.get(`${API_URL}/api/about?populate=*`).then((response) => {
      console.log(response.data.data.attributes);
      setPhoto1(response.data.data.attributes.photo1.data.attributes.url);
      // setPhoto2(response.data.data.attributes.photo2.data.attributes.url)
      setText1(response.data.data.attributes.text1);
      setText2(response.data.data.attributes.text2);
      setLoading(true);
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    getStrapi();
  }, []);
  return (
    <div className="bg-black  min-h-screen text-white">
      <Navbar />
      {loading && (
        <>
          <div className=" text-white  font-domaine mt-36  ml-auto mr-auto text-xl  md:text-3xl w-3/4 md:w-3/5 ">
            <ReactMarkdown
              className="leading-snug"
              children={text1}
              rehypePlugins={[rehypeRaw]}
            />
          </div>
          <div className=" text-white  font-calibreReg mt-10 mb-10 ml-auto mr-auto text-lg  md:text-xl w-3/4 md:w-3/5 ">
            <ReactMarkdown
              className="leading-snug"
              children={text2}
              rehypePlugins={[rehypeRaw]}
            />
          </div>
          <div className="flex justify-center ">
            <img
              className="w-screen md:w-3/4 object-center mb-20"
              src={`${photo1}`}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default About;
