import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import {
  BrowserRouter as Router,
  Link,
  Link as RouterLink,
} from "react-router-dom";
import backArrow from "../assets/arrow_back_rounded.svg";
import { Waypoint } from "react-waypoint";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Carousel } from "react-responsive-carousel";

function Home() {
  const [locations, setLocations] = useState([]);
  const [venues, setVenues] = useState([]);
  const [photo1, setPhoto1] = useState();
  const [photo2, setPhoto2] = useState();
  const [photo3, setPhoto3] = useState();
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [visable, setVisable] = useState(false);
  const [visable1, setVisable1] = useState(false);
  const [visable2, setVisable2] = useState(false);
  const [aboutText, setAboutText] = useState("");
  const [serviceText, setServiceText] = useState("");
  const [locationsText, setLocationsText] = useState("");
  const [carousel, setCarousel] = useState([]);

  const API_URL = process.env.REACT_APP_API_URL;
  const getStrapi = async () => {
    axios.get(`${API_URL}/api/homepage?populate=*`).then((response) => {
      console.log(response.data.data.attributes);
      setCarousel(response.data.data.attributes.carousel.data);
      setPhoto1(response.data.data.attributes.photo1.data.attributes.url);
      setPhoto2(response.data.data.attributes.photo2.data.attributes.url);
      setPhoto3(response.data.data.attributes.photo3.data.attributes.url);
      setText1(response.data.data.attributes.text1);
      setText2(response.data.data.attributes.text2);
      setText3(response.data.data.attributes.text3);
      setAboutText(response.data.data.attributes.about_text);
      setServiceText(response.data.data.attributes.services_text);
      setLocationsText(response.data.data.attributes.locations_text);

      // setLocations(response.data.data.map(i =>i.attributes.map(e => i.location)))
      // setVenues(response.data.data.map(i =>i.attributes))
    });
  };
  const getVenues = async () => {
    axios.get(`${API_URL}/api/locations?populate=*`).then((response) => {
      // setLocations(response.data.data.map(i =>i.attributes.map(e => i.location)))
      setVenues(
        response.data.data.map((i) => {
          return { ...i.attributes, id: i.id };
        })
      );
      console.log(
        response.data.data.map((i) => {
          return { ...i.attributes, id: i.id };
        })
      );
    });
  };

  let carouselPhotoURLs = [];
  if (carousel?.length > 0) {
    carouselPhotoURLs = carousel.map((i) => {
      return i.attributes.url;
    });
  }

  const justLocations = venues.map((i) => {
    return i.location;
  });
  const filteredLocations = [...new Set(justLocations)];

  // localStorage.setItem('locations', filteredLocations)

  const handleClickScroll = () => {
    const element = document.getElementById(1);

    var headerOffset = 45;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const venueList = () => {
    return <div className=""></div>;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getVenues();
    getStrapi();
  }, []);

  const api_url = "http://localhost:1337";

  return (
    <div className="bg-black min-h-screen ">
      {/* <div>api: {process.api_url}</div> */}
      <Navbar />

      <div className="top-0 overscroll-none flex items-center justify-center h-screen ">
        {photo1 && (
          <div className="opacity-75">
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatusshowStatus={false}
              showStatus={false}
              // showIndicators={false}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                const defStyle = {
                  outline: "none",
                  display: "inline-flex",
                  marginLeft: 20,
                  marginBottom: "20px",
                  color: "white",
                  cursor: "pointer",
                  backgroundColor: "black",
                  borderRadius: "50%",
                  height: "12px",
                  width: "12px",
                };
                const style = isSelected
                  ? { ...defStyle, backgroundColor: "white" }
                  : { ...defStyle };
                return (
                  <span
                    style={style}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  >
                    <div style={style}></div>
                  </span>
                );
              }}
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${
                      hasPrev ? "absolute" : "hidden"
                    } w-14 top-0 bottom-0 left-0 flex  items-center p-3  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <img
                      src={backArrow}
                      className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  ml-6 fill-slate-100 cursor-pointer"
                    />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasNext) => {
                return (
                  <div
                    className={`${
                      hasNext ? "absolute" : "hidden"
                    } w-14 top-0 bottom-0 right-0 flex  items-center p-3  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <img
                      src={backArrow}
                      className="w-2 sm:w-10 md:w-[5vw] h-6 sm:h-9  mr-6 fill-slate-100 cursor-pointer -rotate-180 "
                    />
                  </div>
                );
              }}
            >
              {carouselPhotoURLs.map((i, index) => {
                return (
                  <div key={index}>
                    <img
                      className="h-screen w-screen object-cover"
                      src={`${i}`}
                    />
                    {/* <p >Legend 1</p> */}
                  </div>
                );
              })}
            </Carousel>
            {/* <img className='h-screen w-screen top-0 object-cover ' src={`${photo1}`}/> */}
          </div>
        )}
        <div
          className="absolute text-white text-center font-domaine mt-0 mb-10 ml-auto mr-auto text-xl md:text-4xl lg:text-5xl w-3/4 md:w-3/5 "
          id="overview"
        >
          <ReactMarkdown
            className="leading-snug"
            children={text1}
            rehypePlugins={[rehypeRaw]}
          />
        </div>
        {/* <div className='absolute  leading-loose  flex justify-center -mt-36 md:mt-0 pb-10'>
        <p className='text-white text-lg sm:text-2xl md:text-4xl font-domaine w-3/5 text-center '>{text1}</p>
      </div> */}
      </div>

      <div className="absolute w-full flex items-center justify-center align-middle -mt-32  ">
        <Link to="/inquire" component={RouterLink}>
          <div className="flex justify-center -ml-3 left-1/2  w-80 md:w-72 h-12 md:h-10 bg-white  ">
            <div className="text-center text-black text-lg md:text-base my-auto md:mt-2 font-calibreSB">
              INQUIRE
            </div>
          </div>
        </Link>
      </div>

      <div className="flex justify-center mt-20 ">
        {/* <img onClick={() => handleClickScroll()} src={backArrow} className='w-20 h-12 -rotate-90 fill-slate-100 cursor-pointer hidden md:flex'/> */}
      </div>

      <div
        className={`text-white flex flex-col justify-center  transition-opacity ease-in duration-1000 ${
          visable ? "opacity-100" : "opacity-0"
        }`}
      >
        <Waypoint onEnter={() => setVisable(true)} bottomOffset={100}>
          {/* <p className={`text-white text-center font-domaine mt-0 md:mt-20 ml-auto mr-auto mb-10 md:mb-20 text-lg sm:text-2xl md:text-4xl w-1/2 transition-opacity ease-in duration-1000 ${visable? 'opacity-100' : 'opacity-0'}`}>
        {text2}
      </p> */}
          <div
            className="text-white font-domaine  text-center font-thin md:mt-0 mb-14 mx-auto text-lg sm:text-2xl md:text-3xl lg:text-4xl w-1/2  "
            id="overview"
          >
            <ReactMarkdown
              className="leading-normal font-thin"
              children={aboutText}
              rehypePlugins={[rehypeRaw]}
            />
          </div>
        </Waypoint>
        <Link to="/about" component={RouterLink}>
          <div className="flex justify-center  w-80 md:w-72 h-12 md:h-10  mx-auto  mb-20 border border-white text-white hover:text-black hover:bg-white">
            <div className="text-center  text-lg md:text-base my-auto md:mt-2 font-calibreSB">
              ABOUT US
            </div>
          </div>
        </Link>

        <div className="flex justify-center ">
          <img
            className="w-screen md:w-3/4 object-center mb-20"
            src={`${photo1}`}
          />
        </div>
        <div
          id={1}
          className="grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-5  ml-10 mr-10"
        >
          {/* {venues.map((f, index) => {
            return (
              <Link key={index} to='/location' component={RouterLink} >

              <div 
                  onClick={() => {
                    localStorage.setItem('location', f.id)
                    // console.log(f.id)
                  }} 
              className={`cursor-pointer pb-4 max-w-lg mx-auto rounded-xl shadow-lg  transition-opacity ease-in duration-1000 ${visable? 'opacity-100' : 'opacity-0'}`}>
                <img className='aspect-auto h-[60vw] w-[80vw] md:h-[20vw] md:w-[30vw] object-cover mb-3' src={`${f.thumbnail.data.attributes.url}`}/>
                <div className='text-2xl font-calibreSB -mb-1'>{f.name}</div>
              <div className='text-sm font-calibreReg'>{f.location}</div>
             </div>
             </Link>

            )
          })} */}
        </div>

        {/* <p className='text-white text-center font-domaine mt-10 mb-10 ml-auto mr-auto text-lg sm:text-2xl md:text-4xl w-1/2'>{text3}</p> */}
        <Waypoint onEnter={() => setVisable1(true)} bottomOffset={100}>
          <div
            className={`transition-opacity ease-in duration-1000 ${
              visable1 ? "opacity-100" : "opacity-0"
            }`}
          >
            <div
              className="text-white font-domaine  text-center font-thin md:mt-0 mb-10 mx-auto text-lg sm:text-2xl md:text-3xl lg:text-4xl w-1/2 "
              id="overview"
            >
              <ReactMarkdown
                className="leading-snug"
                children={serviceText}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
            <Link to="/services" component={RouterLink}>
              <div className="flex justify-center  w-80 md:w-72 h-12 md:h-10  mx-auto  mb-20 border border-white text-white hover:text-black hover:bg-white">
                <div className="text-center  text-lg md:text-base my-auto md:mt-2 font-calibreSB">
                  OUR SERVICES
                </div>
              </div>
            </Link>
          </div>
        </Waypoint>
        <div className="flex justify-center ">
          <img
            className="w-screen md:w-3/4 object-center mb-10"
            src={`${photo2}`}
          />
        </div>
        <Waypoint onEnter={() => setVisable2(true)} bottomOffset={100} />
        <div
          className={`transition-opacity ease-in duration-1000 ${
            visable2 ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className="text-white font-domaine  text-center font-thin md:mt-0 mb-20 mx-auto text-lg sm:text-2xl md:text-3xl lg:text-4xl w-1/2 "
            id="overview"
          >
            <ReactMarkdown
              className="leading-snug"
              children={locationsText}
              rehypePlugins={[rehypeRaw]}
            />
          </div>

          <Link to="/venues" component={RouterLink}>
            <div className="flex justify-center  w-80 md:w-72 h-12 md:h-10  mx-auto  mb-20 border border-white text-white hover:text-black hover:bg-white">
              <div className="text-center  text-lg md:text-base my-auto md:mt-2 font-calibreSB">
                OUR VENUES
              </div>
            </div>
          </Link>

          <div className="flex justify-center ">
            <img
              className="w-screen md:w-3/4 object-center mb-10"
              src={`${photo3}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
