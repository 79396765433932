import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

function OurStory() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");

  const getData = async () => {
    setLoading(true);
    axios
      .get(`${API_URL}/api/story?populate=*`)
      .then((response) => {
        console.log(response.data.data.attributes);
        setText1(response.data.data.attributes.story1);
        setText2(response.data.data.attributes.story2);
        // setLocations(response.data.data.map(i =>i.attributes.map(e => i.location)))
        // setVenues(response.data.data.map(i =>i.attributes))
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);
  // mr-auto ml-auto sm:mr-0 sm:ml-20
  return (
    <div className="bg-black  min-h-screen text-white">
      <Navbar />
      <div className="flex flex-row justify-center sm:justify-start ml-0 sm:ml-20 mt-24 lg:mt-32 font-calibreSB ">
        Our Story
      </div>

      <div className="flex flex-col sm:flex-row mt-5 lg:mt-10 ">
        <div className="w-3/4 sm:w-1/3 mx-auto md:mx-auto font-domaine text-base md:text-xl lg:text-3xl ml-10 sm:mr-10 leading-relaxed">
          <ReactMarkdown
            className="leading-snug"
            children={text1}
            rehypePlugins={[rehypeRaw]}
          />
        </div>

        <div className="w-3/4 sm:w-1/3 mx-0  mt-10 sm:mt-0 md:mx-auto font-calibreSB text-xs md:text-sm lg:text-base ml-10 sm:ml-10 leading-relaxed">
          <ReactMarkdown
            className="leading-snug"
            children={text2}
            rehypePlugins={[rehypeRaw]}
          />
        </div>
      </div>
    </div>
  );
}

export default OurStory;
